import React from "react";
import { Wrapper, Spacing, WidthWrapper } from "../../../components/Layout";
import Box from '@mui/material/Box';
import { StyledH3 } from "../../../components/Text";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import { features } from "../../../config/constants/features";

const ImgContainer = styled('div')({
    background: "rgba(69, 237, 213, 0.15)",
    boxShadow: "0px 6px 12px rgb(226 251 249/35%)",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "150px",
    marginBottom: "15px",
    borderRadius: "45px"
})

const StyledImg = styled('img')(({ theme }) => ({
    width: "75px",
    height: "75px",

    [theme.breakpoints.down('sm')]: {
        width: "50px",
        height: "50px"
    },
}))

const Features = () => {
    return (
        <Wrapper style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <WidthWrapper>
                <Grid container spacing={10}>
                    {
                        features.map(feature => (
                            <Grid container item xs={12} sm={6} md={4} alignItems="center" justifyContent="center" key={feature.fid}>
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}>
                                    <ImgContainer>
                                        <StyledImg src={feature.image} alt="features" />
                                    </ImgContainer>
                                    <StyledH3>{feature.title}</StyledH3>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </WidthWrapper>
            <Spacing />
        </Wrapper >

    )
}

export default Features