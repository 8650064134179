import React from "react";
import { Wrapper, Spacing, WidthWrapper } from "../../../components/Layout";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { StyledH2, StyledText, StyledH3 } from "../../../components/Text";
import { styled } from "@mui/system";
import stepBg from "../../../assets/images/home/steps/step-bg.png"
import stepArrow from "../../../assets/images/home/steps/step-arrow.svg"
import step1 from "../../../assets/images/home/steps/step1.png"
import step2 from "../../../assets/images/home/steps/step2.png"
import step3 from "../../../assets/images/home/steps/step3.png"
import { useWindowWidth } from "../../../hooks/useWindowWidth";

const StepContainer = styled('div')({
    backgroundImage: `url(${stepBg})`,
    height: "175px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "175px",
    margin: "auto",
    marginBottom: "15px",
})

const Steps = () => {
    const isShow = useWindowWidth(900)

    return (
        <Wrapper>
            <WidthWrapper>
                <Box>
                    <Grid container spacing={2}>
                        <Grid container item sm={12} md={6} alignItems="center" justifyContent="start">
                            <Box>
                                <StyledH2>It’s easy as 1, 2, 3</StyledH2>
                                <StyledText>Getting started with DeFi Wonderland is easy. If you’re new to crypto, a DeFi nerd, or even a business looking to tap into extra wealth; simply follow the prompts &amp; let the platform do the heavy lifting</StyledText>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={2} marginTop="10px" columns={11}>
                    <Grid container item sm={12} md={3} alignItems="start" justifyContent="center">
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <StepContainer>
                                <img src={step1} alt="step1" />
                            </StepContainer>
                            <StyledH3>Step 1</StyledH3>
                            <StyledText>Connect Your Wallet</StyledText>
                        </Box>
                    </Grid>

                    {
                        isShow && <Grid container item sm={12} md={1} alignItems="center" justifyContent="center">
                            <img src={stepArrow} alt="stepArrow" />
                        </Grid>
                    }


                    <Grid container item sm={12} md={3} alignItems="start" justifyContent="center">
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <StepContainer>
                                <img src={step2} alt="step1" />
                            </StepContainer>
                            <StyledH3>Step 2</StyledH3>
                            <StyledText>Make a Deposit</StyledText>
                        </Box>
                    </Grid>

                    {
                        isShow && <Grid container item sm={12} md={1} alignItems="center" justifyContent="center">
                            <img src={stepArrow} alt="stepArrow" />
                        </Grid>
                    }

                    <Grid container item sm={12} md={3} alignItems="start" justifyContent="center">
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <StepContainer>
                                <img src={step3} alt="step1" />
                            </StepContainer>
                            <StyledH3>Step 3</StyledH3>
                            <StyledText style={{ textAlign: "center" }}>Start Investing, trading and earning</StyledText>
                        </Box>
                    </Grid>
                </Grid>
            </WidthWrapper>

            <Spacing />
        </Wrapper >

    )
}

export default Steps