import React from "react"
import { HiMenuAlt4 } from "react-icons/hi"
import { StyledIconButton } from "../elements/Toolbar"
import { StyledSidebarMenu, StyledSidebarMenuItem, SidebarLinks } from "../elements/Dropdown";
import { appBarItems } from "../../../config/constants/menus";

const SidebarMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <StyledIconButton
                size="large"
                aria-controls="basic-menu"
                edge="start"
                aria-label="menu"
                onClick={handleClick}
            >
                <HiMenuAlt4 />
            </StyledIconButton>

            <StyledSidebarMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >{
                    appBarItems.map(item => (
                        <SidebarLinks href={item.link} key={item.aid} target="_blank">
                            <StyledSidebarMenuItem onClick={handleClose}>{item.text}</StyledSidebarMenuItem>
                        </SidebarLinks>
                    ))
                }
            </StyledSidebarMenu>

        </div>

    )
}

export default SidebarMenu