import { FeaturesConfig } from "../types";
import feature1 from "../../assets/images/home/features/feature-1.png"
import feature2 from "../../assets/images/home/features/feature-2.png"
import feature3 from "../../assets/images/home/features/feature-3.png"
import feature4 from "../../assets/images/home/features/feature-4.png"
import feature5 from "../../assets/images/home/features/feature-5.png"
import feature6 from "../../assets/images/home/features/feature-6.png"
import feature7 from "../../assets/images/home/features/feature-7.png"
import feature8 from "../../assets/images/home/features/feature-8.png"
import feature9 from "../../assets/images/home/features/feature-9.png"
import feature10 from "../../assets/images/home/features/feature-10.png"
import feature11 from "../../assets/images/home/features/feature-11.png"
import feature12 from "../../assets/images/home/features/feature-12.png"

export const features: FeaturesConfig[] = [
    {
        fid: 1,
        title: "Decentralised Hedge Funds",
        image: feature1
    },
    {
        fid: 2,
        title: "Stablecoin Investment Accounts",
        image: feature2,
    },
    {
        fid: 3,
        title: "Staking Pools",
        image: feature3,
    },
    {
        fid: 4,
        title: "Lending Protocol",
        image: feature4,
    },
    {
        fid: 5,
        title: "Yield Farming Aggregator",
        image: feature5,
    },
    {
        fid: 6,
        title: "Project Launchpad",
        image: feature6,
    },
    {
        fid: 7,
        title: "NFT Marketplace",
        image: feature7,
    },
    {
        fid: 8,
        title: "Swapping",
        image: feature8,
    },
    {
        fid: 9,
        title: "Affiliate Earnings",
        image: feature9,
    },
    {
        fid: 10,
        title: "Investment analytics",
        image: feature10,
    },
    {
        fid: 11,
        title: "Planet Earth Fund",
        image: feature11,
    },
    {
        fid: 12,
        title: "Insurance Protocol",
        image: feature12,
    }
]