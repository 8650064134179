import React from "react";
import { Wrapper, Spacing, WidthWrapper } from "../../../components/Layout";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { StyledH1, StyledText } from "../../../components/Text";
import { PrimaryButton } from "../../../components/Button";
import IntroImg from "../../../assets/images/home/phone-banner.png"
import { StyledImgContent } from "../HomeElements";
import { styled } from "@mui/system";

const StyledTitle = styled(StyledH1)({
    fontWeight: 400
})

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: "center",
    justifyContent: "start",

    [theme.breakpoints.down('sm')]: {
        justifyContent: "center"
    },
}))

const newPage = () => {
    window.open("https://forms.gle/YB4GbMACVFpp9Hzw6", '_blank');
}

const Intro = () => {
    return (
        <Wrapper>
            <WidthWrapper>
                <Grid container spacing={2}>
                    <Grid container item sm={12} md={6} alignItems="center" justifyContent="center">
                        <div>
                            <StyledTitle>Bringing <strong style={{ fontWeight: 900 }}>Decentralised Finance</strong> To Enterprise,
                                Business, and The Masses
                            </StyledTitle>
                            <StyledText>DeFi Wonderland is building the open banking infrastructure of the future. We're connecting the Blockchain’s greatest opportunities & highest yields, integrated with unique enterprise solutions to reach the masses.</StyledText>
                            <StyledBox>
                                <PrimaryButton width="180px" onClick={newPage}>Join Whitelist</PrimaryButton>
                            </StyledBox>
                        </div>
                    </Grid>

                    <Grid container item sm={12} md={6} alignItems="center" justifyContent="center">
                        <StyledImgContent src={IntroImg} alt="banner" />
                    </Grid>
                </Grid>
            </WidthWrapper>

            <Spacing />
        </Wrapper>

    )
}

export default Intro