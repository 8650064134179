import React from "react"
import { FooterWrapper } from "../Layout"
import { FooterContainer, LogoImg, ImgContainer, FooterLink, FooterLinkItems, SocialWrap, SocialLink } from "./elements"
import Box from '@mui/material/Box';
import { StyledH5 } from "../Text";
import { Grid } from "@mui/material";
import CollapseLink from "./CollapseLink";
import { FaTwitter, FaFacebookF, FaDiscord, FaInstagram, FaTelegram } from "react-icons/fa"
import { CollapseLinkProps } from "./types";
import { VariantButton } from "../Button";
import { useWindowWidth } from "../../hooks/useWindowWidth";

const Footer = () => {
    const windowWidth = useWindowWidth(900)
    const navigateLinks: CollapseLinkProps = {
        title: "Navigate",
        content: [
            {
                id: "001",
                text: "Exchange",
                link: "https://defiwonderland.github.io/dwl-dapp/#/trade"
            },
            {
                id: "002",
                text: "Liquidity",
                link: "https://defiwonderland.github.io/dwl-dapp/#/trade"
            },
            {
                id: "003",
                text: "Farms",
                link: "https://defiwonderland.github.io/dwl-dapp/#/farms"
            },
        ]
    }

    const newPage = () => {
        window.open("https://defiwonderland.github.io/dwl-dapp/#/", '_blank');
    }

    const socialIconJustifyContent = windowWidth ? "end" : "center"

    return (
        <FooterWrapper>
            <FooterContainer>
                <Box sx={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "space-between"
                }
                }>
                    <ImgContainer href="/">
                        <LogoImg src="./images/logo-white.svg" />
                    </ImgContainer>

                    <VariantButton width="200px" style={{ color: "#ffffff" }} onClick={newPage}>Enter dApp Testnet</VariantButton>
                </Box>

                <div style={{ margin: "50px 0" }} />

                <Grid container spacing={0}>
                    <Grid container item sm={12} md={6} alignItems="start">
                        {
                            windowWidth ? <div style={{ width: "100%" }}>
                                <StyledH5>{navigateLinks.title}</StyledH5>

                                <Grid container spacing={2}>
                                    {
                                        navigateLinks.content.map(item => (
                                            <Grid container item sm={4} key={item.id}>
                                                <FooterLinkItems >
                                                    <FooterLink href={item.link} target="_blank">{item.text}</FooterLink>
                                                </FooterLinkItems>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div> : <CollapseLink title={navigateLinks.title} content={navigateLinks.content} />
                        }
                    </Grid>

                    <Grid container item sm={12} md={6} alignItems="center" justifyContent={socialIconJustifyContent}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <SocialWrap>
                                <SocialLink href="https://mobile.twitter.com/defiwonderland_" target="_blank">
                                    <FaTwitter />
                                </SocialLink>
                            </SocialWrap>

                            <SocialWrap>
                                <SocialLink href="https://www.facebook.com/DeFi-Wonderland-101812302267774/" target="_blank" >
                                    <FaFacebookF />
                                </SocialLink>
                            </SocialWrap>

                            <SocialWrap>
                                <SocialLink href="https://discord.gg/xcF7uXzf" target="_blank">
                                    <FaDiscord />
                                </SocialLink>
                            </SocialWrap>

                            <SocialWrap>
                                <SocialLink href="https://www.instagram.com/defiwonderland/" target="_blank">
                                    <FaInstagram />
                                </SocialLink>
                            </SocialWrap>

                            <SocialWrap>
                                <SocialLink href="https://t.me/defi_wonderland" target="_blank">
                                    <FaTelegram />
                                </SocialLink>
                            </SocialWrap>
                        </Box>
                    </Grid>
                </Grid>
            </FooterContainer>
        </FooterWrapper >
    )
}

export default Footer