import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    StyledToolbar,
    StyledAppBar,
    LogoImg,
    ImgContainer,
    NavMenu,
    NavItem,
    NavLinks,
} from './elements/Toolbar';
import SidebarMenu from './components/SidebarMenu';
import { VariantButton } from '../Button';
import { appBarItems } from '../../config/constants/menus';

function NavBar() {
    const logoState = window.innerWidth >= 900 ? false : true
    const [changeLogo, setChangeLogo] = useState<boolean>(logoState)
    const [scroll, setScroll] = useState<boolean>(false)

    const updateLogo = () => {
        if (window.innerWidth >= 900) {
            setChangeLogo(false)
        } else {
            setChangeLogo(true)
        }
    }

    const changeNav = () => {
        if (window.scrollY > 0) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    const newPage = () => {
        window.open("https://defiwonderland.github.io/dwl-dapp/#/", '_blank');
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
        window.addEventListener('resize', updateLogo)

        return () => {
            window.removeEventListener('scroll', changeNav)
            window.removeEventListener('resize', updateLogo)
        }
    }, [])

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <StyledAppBar
                    position="fixed"
                    scroll={scroll.toString()}
                >
                    <StyledToolbar>
                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <SidebarMenu />

                            <ImgContainer href="/">
                                {
                                    !changeLogo ? <LogoImg src="./images/logo.svg" /> : <LogoImg src="./images/mini-logo.svg" />
                                }
                            </ImgContainer>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <NavMenu>
                                {
                                    appBarItems.map(item => (
                                        <NavLinks href={item.link} target="_blank" key={item.aid}>
                                            <NavItem>{item.text}</NavItem>
                                        </NavLinks>
                                    ))
                                }
                            </NavMenu>

                            <VariantButton width="200px" onClick={newPage}>Enter dApp Testnet</VariantButton>
                        </Box>
                    </StyledToolbar>
                </StyledAppBar>
            </Box >
        </>
    );
}

export default NavBar