import { AppbarConfig } from "../types";

export const appBarItems: AppbarConfig[] = [
    {
        aid: 0,
        text: "Whitelist",
        link: "https://forms.gle/YB4GbMACVFpp9Hzw6",
    },
    {
        aid: 1,
        text: "Github",
        link: "https://github.com/orgs/defiwonderland/repositories",
    },
    {
        aid: 2,
        text: "Private Sale Enquiry",
        link: "https://forms.gle/hXnmJx7JL3jEqipr8",
    },
    {
        aid: 3,
        text: "Info",
        link: "https://docs.defiwonderland.finance/",
    },
]