import React from "react"
import { Wrapper, WidthWrapper } from "../../../components/Layout";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { StyledH2, StyledText } from "../../../components/Text";
import { PrimaryButton } from "../../../components/Button";
import { StyledImgContent } from "../HomeElements";
import { HorizontalContentProps } from "./types";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: "center",
    justifyContent: "start",

    [theme.breakpoints.down('sm')]: {
        justifyContent: "center"
    },
}))

const HorizontalContent: React.FC<HorizontalContentProps> = ({
    title,
    desc,
    buttonText,
    img,
    rowReverse,
    handleClick,
}) => {
    const direction = rowReverse ? "row-reverse" : "row"

    return (
        <Wrapper style={{ paddingTop: "0px" }}>
            <WidthWrapper>
                <Grid container spacing={0} direction={direction}>
                    <Grid container item sm={12} md={6} alignItems="center" justifyContent="start">
                        <Box>
                            <StyledH2>{title}</StyledH2>
                            <StyledText>{desc}</StyledText>
                            {
                                buttonText && <StyledBox>
                                    <PrimaryButton width="250px" minheight="50px" style={{ margin: "0px" }} onClick={handleClick}>{buttonText}</PrimaryButton>
                                </StyledBox>
                            }
                        </Box>
                    </Grid>

                    <Grid container item sm={12} md={6} alignItems="center" justifyContent="start">
                        <StyledImgContent src={img} />
                    </Grid>
                </Grid>
            </WidthWrapper>
        </Wrapper>
    )
}

export default HorizontalContent