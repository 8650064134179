import { styled } from "@mui/system";

export const StyledImgContent = styled("img")(({ theme }) => ({
    maxWidth: "75%",
    height: "auto",

    [theme.breakpoints.between("md", "lg")]: {
        maxWidth: "95%",
    },

    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
    },
}))