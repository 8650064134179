import React from "react";
import { Wrapper, Spacing, WidthWrapper } from "../../../components/Layout";
import Box from '@mui/material/Box';
import { StyledH2 } from "../../../components/Text";
import bridgingImg from "../../../assets/images/home/decentralised-img.png"
import { styled } from "@mui/system";

const StyledImg = styled('img')(({ theme }) => ({
    width: "100%",

    [theme.breakpoints.down('sm')]: {
        height: "auto",
    },
}))

const Bridging = () => {

    return (
        <Wrapper>
            <WidthWrapper>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}>
                    <StyledH2 style={{ textAlign: "center" }}>Bridging Traditional Finance To Decentralised Finance</StyledH2>

                    <Box sx={{
                        display: "block",
                        margin: "0px auto",
                    }}>
                        <StyledImg src={bridgingImg} alt="bridging" />
                    </Box>

                </Box>
            </WidthWrapper>

            <Spacing />
        </Wrapper >

    )
}

export default Bridging