import { Route, Switch, Redirect } from "react-router-dom"
import Home from './pages/Home'


function Routes(): JSX.Element {
    return (
        <Switch>
            <Route exact strict path="/" component={Home} />
            <Redirect to="/" />
        </Switch>
    )
}

export default Routes