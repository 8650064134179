import React from "react";
import Intro from "./Intro";
import Steps from "./Steps";
import Bridging from "./Bridging";
import VerticalContent from "./VerticalContent"
import { Box } from "@mui/system";
import HorizontalContent from "./HorizontalContent";
import manageImg from "../../assets/images/home/manage-image.png"
import investImg from "../../assets/images/home/invest-image.png"
import earnImg from "../../assets/images/home/earn-image.png"
import Features from "./Features";
import BottomImg from "./BottomImg";

const Home: React.FC = () => {
    const desc1 = `“Proven platforms, strategies and technologies are integrated into the framework of Wonderland, bringing the Blockchain's best into a single hub”`
    const desc2 = "Currently being built by the Wonderland team for Fortune 1000 clients, businesses, & people"
    const desc3 = "Follow the White Rabbit into Wonderland and begin the magical journey of intelligent DeFi"

    return (
        <section style={{ marginTop: "70px" }}>
            <Intro />
            <Steps />
            <Bridging />
            <VerticalContent title="The Entire DeFi World In One Place" desc={desc1} />
            <Box>
                <HorizontalContent
                    title="MANAGE"
                    desc="View the performance of your investments &amp; entire crypto portfolio with data that makes you wiser."
                    img={manageImg}
                    rowReverse={false}
                />

                <HorizontalContent
                    title="INVEST"
                    buttonText="Register for Whitelist"
                    desc="Decentralised Hedge Funds, Investment Pools, &amp; Launch Pads bring the greatest opportunities to you"
                    img={investImg}
                    rowReverse={true}
                    handleClick={() => window.open("https://forms.gle/YB4GbMACVFpp9Hzw6", '_blank')}
                />

                <HorizontalContent
                    title="EARN"
                    desc="Earn interest (yield) on your crypto with 7 unique ways to earn; our platform sources the best of the best for you."
                    img={earnImg}
                    rowReverse={false}
                />
            </Box>

            <VerticalContent title="Wonderland Features" desc={desc2} />
            <Features />
            <VerticalContent
                title="Register for Whitelist"
                desc={desc3}
                buttonText="Register for Whitelist"
                handleClick={() => window.open("https://forms.gle/YB4GbMACVFpp9Hzw6", '_blank')}
            />
            <BottomImg />

        </section>
    )
}

export default Home