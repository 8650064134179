import { styled } from "@mui/system";

export const Wrapper = styled('div')(({ theme }) => ({
    padding: "80px 60px",
    margin: "0 auto",

    [theme.breakpoints.down("md")]: {
        padding: "40px 30px",
    },
}))

export const WidthWrapper = styled('div')(({ theme }) => ({
    width: "80%",
    display: "block",
    margin: "0 auto",

    [theme.breakpoints.down("lg")]: {
        width: "100%",
    },
}))

export const FooterWrapper = styled('div')(({ theme }) => ({
    paddingBottom: "30px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
}))

export const TextContainer = styled('div')(({ theme }) => ({
    width: "50%",

    [theme.breakpoints.down("md")]: {
        width: "90%",
    },
}))

export const CenterConatiner = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "start",

    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
    },
}))

export const Spacing = styled('div')(({ theme }) => ({
    margin: "24px 0",

    [theme.breakpoints.down("md")]: {
        margin: "0px",
    },
}))


